import React from 'react'
import classNames from 'classnames'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Header from 'components/Header/Header.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'

import mainPageStyle from 'assets/jss/styles/pages/mainPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(mainPageStyle)

export default function ErrorPage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
  })
  const classes = useStyles()
  return (
    <div>
      <Header
        color="primary"
        brand="Suburled"
        links={<HeaderLinks dropdownHoverColor="primary" />}
        fixed
      />
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <div className={classes.container}>
              <h1>Ups! ha habido un error</h1>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  )
}

